import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
$("header .nav li").eq(0).addClass("active");

$(function () {
  $(window).scroll(function () {
      // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
      if ($(window).scrollTop() >= ($("header").height())) {
          // alert($("#slideBox").height());
          $("header").addClass("head1");
      } else {
          $("header").removeClass("head1");
      }
  })
})

var swiper = new Swiper('.index-banner .swiper-container', {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed:500,
    spaceBetween: 30,
    effect: 'fade',
    loop:true,//必须
    navigation: {
      nextEl: '.index-banner .swiper-button-next',
      prevEl: '.index-banner .swiper-button-prev',
    },
    on: {
      init: function (swiper) {
          let total = $(".index-banner").attr("data")
          if (total < 10) {
              $('.swiper-num .total').text("0" + total);
          } else {
              $('.swiper-num .total').text(total);
          }
          this.emit('transitionEnd');
      },
      transitionEnd: function () {
        var index = this.realIndex + 1;
        if (index < 10) {
          $(".swiper-num .active").text("0" + index);
        } else {
          $(".swiper-num .active").text(index);
        }
      }
  }
});
// 视频播放
// $(".play").click(function () {
//     var video = document.getElementById('myVideo');
//     if (video.paused) { //如果已暂停则播放
//         $('.play').hide();
//         video.play(); //播放控制
//     } else { // 已播放点击则暂停
//         $('.play').show();
//         video.pause(); //暂停控制
//     }
// })

// 产品tab切换
$(".section1 .left .pic").eq(0).addClass("active")
$(".section1 .right ul li").eq(0).addClass("active");
$(".section1 .right ul li").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".section1 .left .pic").eq(index).addClass("active").siblings().removeClass("active");
})
// 案例tab切换
$(".case-nav ul li").eq(0).addClass("active")
$(".case-pic .swiper").eq(0).addClass("active");
$(".case-nav ul li").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".case-pic .swiper").eq(index).addClass("active").siblings().removeClass("active");
})
var case1 = new Swiper(".case-pic .case1", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});
var case2 = new Swiper(".case-pic .case2", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});
var case3 = new Swiper(".case-pic .case3", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});
var case4 = new Swiper(".case-pic .case4", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});
var case5 = new Swiper(".case-pic .case5", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});
var case6 = new Swiper(".case-pic .case6", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});
var case7 = new Swiper(".case-pic .case7", {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed:500,
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    320: { //当屏幕宽度大于等于320
    slidesPerView: 1,
    },
    768: { //当屏幕宽度大于等于768 
      slidesPerView: 2,
        spaceBetween: 30,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
    }
  },
});


// 关于我们轮播
var swiper = new Swiper('.section3 .swiper1', {
  loop:true,
  navigation: {
    nextEl: '.section3 .swiper-button-next',
    prevEl: '.section3 .swiper-button-prev',
  },
});
var swiper = new Swiper('.section3 .swiper2', {
  loop:true,
  initialSlide:1,
  navigation: {
    nextEl: '.section3 .swiper-button-next',
    prevEl: '.section3 .swiper-button-prev',
  },
});